import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

// Variável global para armazenar a instância do STOMP
let stompClient = null;

export default {
  namespaced: true,
  state() {
    return {
      isConnected: false, // Apenas controla se está conectado
    };
  },
  mutations: {
    setConnected(state, isConnected) {
      state.isConnected = isConnected;
    },
  },
  actions: {
    connectStompClient({ commit }) {
      if (stompClient !== null && stompClient.connected) {
        console.log("🟢 WebSocket já conectado");
        return;
      }

      const token = JSON.parse(localStorage.getItem("mizunetoken"));
      if (!token) {
        console.error("❌ Token não encontrado");
        return;
      }

      const socket = new SockJS("http://localhost:8081/ws", null, {
        withCredentials: true,
      });

      stompClient = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        connectHeaders: {
          Authorization: token,
        },
        onConnect: () => {
          console.log("✅ Conectado ao WebSocket");
          commit("setConnected", true);
        },
        onDisconnect: () => {
          console.log("❌ Desconectado do WebSocket");
          commit("setConnected", false);
        },
      });

      stompClient.activate();
    },

    disconnectStompClient({ commit }) {
      if (stompClient !== null) {
        stompClient.deactivate();
        commit("setConnected", false);
        console.log("❌ WebSocket desconectado");
      }
    },
  },
  getters: {
    isConnected(state) {
      return state.isConnected;
    },
    getStompClient() {
      return stompClient;
    },
  },
};
