import { createStore } from 'vuex'
import websocket from './modules/websocket';

export default createStore({
    modules: {
      websocket, // Registra o módulo
    },
    state () {
      return {
        usuario: null,
        listaSistemas: null,
      }
    },
    mutations: {
        setUser(state, usuario) {
            state.usuario = usuario;
        },
        setSistemas(state, listaSistemas) {
            state.listaSistemas = listaSistemas;
        },
    },   
  })
